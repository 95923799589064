import React, { useEffect, useState } from 'react';
import { Flex, Image, Text } from 'rebass';
import { Link } from 'gatsby';
import * as Sentry from '@sentry/browser';
import { useTranslation } from 'react-i18next';

import { navigateTo } from '../../components/functional/Navigate';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { theme } from '../../layouts/main.layout';
import { VOZZiWebsitePage } from '../../types/VozziWebsitePage.type';
import { VozziPrimaryButton } from '../../components/layout/_/button.component';
import Dialog from '../../components/functional/SweetAlert';
import { APIs } from '../../configs/env';

import { cancelButtonCss, confirmationBoxCss } from './unsubscribe.css';

const UnsubscribePage: VOZZiWebsitePage = (props) => {
  const [hashUserId, setHashUserId] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [hashUserIdExists, setHashUserIdExists] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);

    const hashId = searchParams.get('userId');
    const email = searchParams.get('email');

    setHashUserId(hashId ? hashId : '');
    setUserEmail(email ? email : '');

    setHashUserIdExists(hashId ? true : false);
  });

  const handleSubmit = async () => {
    const url = new URL(`${APIs.mainServerApiUrl}/v1/emails-unsubscribe/${hashUserId}`);

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    try {
      const response = await fetch(url.toString(), {
        method: 'GET',
        headers: headers,
      });

      if (response.status === 204) {
        Dialog.fire({
          icon: 'success',
          title: t('response_SuccessUnsubscribe'),
          confirmButtonColor: theme.colors.vozziVividOrange,
        });

        navigateTo('/sr');
      }
    } catch (err) {
      Sentry.captureException(err);
      Dialog.fire({
        title: t('response_Error'),
        icon: 'error',
        confirmButtonColor: theme.colors.vozziVividOrange,
      });
    }
  };

  return (
    <HomePageLayout>
      <Flex mb={4}>
        <Flex css={confirmationBoxCss} flexDirection="column" justifyContent="space-between" alignItems="center">
          <Image src="/img/subscription-baloon@3x.png" />

          {userEmail ? (
            <Text mt={4} style={{ fontWeight: 'bold' }}>
              {userEmail}
            </Text>
          ) : null}

          {hashUserIdExists ? (
            <>
              <Text mt={userEmail ? 2 : 4}>{t('unsubscribeTitleBox')}</Text>

              <Flex mt={4}>
                <Link to={t('link_home')} css={cancelButtonCss}>
                  {t('unsubscribeCancelButton')}
                </Link>

                <VozziPrimaryButton width="130px" onClick={handleSubmit}>
                  {t('unsubscribeConfirmButton')}
                </VozziPrimaryButton>
              </Flex>
            </>
          ) : (
            <>
              <Text style={{ textAlign: 'center' }} mt={userEmail ? 2 : 4} mb={4}>
                {t('hashUserIdNotFoundUnsubscriptionMsg')}
              </Text>

              <Link to={t('link_home')} css={cancelButtonCss}>
                {t('btn_Ok')}
              </Link>
            </>
          )}
        </Flex>
      </Flex>
    </HomePageLayout>
  );
};

export default UnsubscribePage;
