import { css } from '@emotion/core';
import { theme } from '../../layouts/main.layout';

export const confirmationBoxCss = css`
  width: 450px;
  margin: auto;
  background-color: white;
  margin-top: 5%;
  padding: 50px 0px;
  border-radius: 8px;
  box-shadow: 2px 20px 30px -10px rgba(0, 0, 0, 0.1);
  border: solid 0.6px ${theme.colors.borderGray};
`;

export const cancelButtonCss = css`
  background-color: white;
  margin-right: 15px;
  padding: 12px 24px;
  color: #716d6d;
  border: solid 1px ${theme.colors.borderGray};
  border-radius: 8px;
  cursor: pointer;

  :hover {
    text-decoration: none;
    color: #716d6d;
  }
`;
